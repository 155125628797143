<template>
  <div>
    <!--desktop design-->
    <div
      class="py-20 bg-gradient-to-b from-[#0F2377] to-[#00102F] hidden xl:block mt-[2rem]"
    >
      <section class="lg:w-[95%] 2xl:w-[70%] mx-auto w-full min-h-[600px]">
        <div class="p-5 lg:px-4 flex flex-col gap-2">
          <div class="flex justify-between lg:m-4 xl:m-0 items-center">
            <h1 class="text-[#F8C300] md:text-[35px] text-[24px] font-bold">
              Alumni Speak
            </h1>
          </div>

          <div
            class="flex flex-row flex-wrap gap-5 justify-center lg:m-4 xl:m-0"
          >
            <div
              class="lg:w-[280px] lg:h-[493px] relative"
              v-for="data in visibleAlumniData"
              :key="data.id"
            >
              <template v-if="data.id == is_active_video">
                <iframe
                  width="280"
                  height="493"
                  :src="data.link"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </template>
              <template v-else>
                <div class="" @click="is_active_video = data.id">
                  <div
                    class="absolute bottom-0 left-0 w-full h-full"
                    style="
                      background: transparent
                        linear-gradient(180deg, #00000000 0%, #000000 100%) 0%
                        0% no-repeat padding-box;
                      box-shadow: 0px 1px 6px #00000029;
                      border-radius: 14px;
                      opacity: 1;
                      height: 150px;
                    "
                  ></div>
                  <img
                    :src="data.bannerImg"
                    alt=""
                    class="w-full rounded-2xl"
                  />
                  <div class="flex justify-center items-center">
                    <img
                      src="@/assets/lpOnline/play.svg"
                      alt=""
                      srcset=""
                      class="absolute top-[200px] md:top-[190px] w-[75px] h-[75px]"
                    />
                  </div>

                  <div class="flex justify-center">
                    <img
                      :src="data.smallImg"
                      alt=""
                      class="rounded-full w-[120px] h-[120px] absolute bottom-[17%] border p-2 border-[#F8C300]"
                    />

                    <p class="text-white absolute bottom-[20px]">
                      <span class="text-[24px] font-bold">{{ data.name }}</span>
                      <br />
                      {{ data.subject }}
                    </p>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div class="py-5">
            <button
              v-if="visibleVideosCount < aluminaiData.length"
              @click="loadMore"
              class="bg-[#F8C300] text-[#001C54] w-full md:w-[160px] text-[16px] py-[13px] px-4 rounded-3xl button-cta"
            >
              Load More
            </button>
            <button
              v-if="visibleVideosCount > 4"
              @click="loadLess"
              class="bg-[#F8C300] text-[#001C54] w-full md:w-[160px] text-[16px] py-[13px] px-4 rounded-3xl button-cta"
            >
              Load Less
            </button>
          </div>
        </div>
      </section>
    </div>
    <!--desktop design-->
    <!--mob design-->
    <div
      class="bg-gradient-to-b from-[#0F2377] to-[#00102F] py-5 xl:hidden mt-[2rem]"
    >
      <section class="max-w-7xl mx-auto w-full min-h-[600px]">
        <div class="p-5 flex flex-col gap-2">
          <div class="flex justify-between items-center">
            <h1 class="text-[#F8C300] md:text-[35px] text-[24px] font-bold">
              Alumni Speak
            </h1>
          </div>

          <div class="flex aluminai-speak-carousel">
            <div
              class="w-full relative"
              v-for="data in aluminaiData"
              :key="data.id"
            >
              <template v-if="data.id == is_active_video">
                <iframe
                  class="w-full"
                  width=""
                  height="493"
                  :src="data.link"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </template>
              <template v-else>
                <div class="" @click="is_active_video = data.id">
                  <div
                    class="absolute bottom-0 left-0 w-full h-full"
                    style="
                      background: transparent
                        linear-gradient(180deg, #00000000 0%, #000000 100%) 0%
                        0% no-repeat padding-box;
                      box-shadow: 0px 1px 6px #00000029;
                      border-radius: 14px;
                      opacity: 1;
                      height: 150px;
                    "
                  ></div>
                  <img
                    :src="data.bannerImg"
                    alt=""
                    class="w-full h-[493px] rounded-2xl"
                  />
                  <div class="flex justify-center items-center">
                    <img
                      src="@/assets/lpOnline/play.svg"
                      alt=""
                      srcset=""
                      class="absolute top-[180px] md:top-[200px] w-[75px] h-[75px]"
                    />
                  </div>

                  <div class="flex justify-center">
                    <img
                      :src="data.smallImg"
                      alt=""
                      class="rounded-full w-[120px] h-[120px] absolute bottom-[17%] border p-2 border-[#F8C300]"
                    />

                    <p class="text-white absolute bottom-[20px]">
                      <span class="text-[24px] font-bold">
                        {{ data.name }}
                      </span>
                      <br />
                      {{ data.subject }}
                    </p>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!--mob design-->
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
export default {
  name: "AluminaiSpeak",
  data() {
    return {
      is_active_video: "0",
      visibleVideosCount: 4,
      aluminaiData: [
        {
          id: 1,
          bannerImg: require("@/assets/lpOnline/JO-Testimonial-Mobile-290.97x493-Anirudh-Chawat-May_07.webp"),
          smallImg: require("@/assets/lpOnline/JO-Testimonialrounded-114px-Anirudh-Chawat-May_07.webp"),
          name: "Aniruddh Chawat",
          subject: "MBA HR and Finance",
          link: "https://api.onlinejain.com/media/9_16_Final/Aniruddh_9_16.mp4",
        },
        {
          id: 2,
          bannerImg: require("@/assets/lpOnline/JO-Testimonial-Mobile-290.97x493-Hiba-May_07.webp"),
          smallImg: require("@/assets/lpOnline/JO-Testimonialrounded-114px-Hiba-May_07.webp"),
          name: "Heba Kousar",
          subject: " MBA HR and Finance",
          link: "https://api.onlinejain.com/media/9_16_Final/Heba_9_16.mp4",
        },
        {
          id: 3,
          bannerImg: require("@/assets/lpOnline/JO-Testimonial-Mobile-290.97x493-Kalpita-May_07.webp"),
          smallImg: require("@/assets/lpOnline/JO-Testimonialrounded-114px-Kalpita-May_07.webp"),
          name: "Kalpitha S",
          subject: "MBA Finance and Marketing",
          link: "https://api.onlinejain.com/media/9_16_Final/Kalpita_9_16.mp4",
        },
      ],
    };
  },
  beforeMount() {
    const elementToRemoveDes = document.querySelector(
      ".aluminai-speak-carousel"
    );

    if (elementToRemoveDes) {
      elementToRemoveDes.remove();
    }
  },
  mounted() {
    $(`.aluminai-speak-carousel`).slick({
      infinite: true,
      autoplay: true,
      prevArrow: false,
      nextArrow: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,

      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  },
  computed: {
    visibleAlumniData() {
      return this.aluminaiData.slice(0, this.visibleVideosCount);
    },
  },
  methods: {
    loadMore() {
      this.visibleVideosCount = Math.min(
        this.visibleVideosCount + 4,
        this.aluminaiData.length
      );
    },
    loadLess() {
      this.visibleVideosCount = Math.max(this.visibleVideosCount - 4, 4); // Ensure minimum of 4 videos are displayed
    },
  },
};
</script>

<style>
/* .slick-dotsAluminaiSpeak {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 1rem 0;
    list-style-type: none;
  }
  .slick-dotsAluminaiSpeak li {
    margin: 0 0.25rem;
  }
  .slick-dotsAluminaiSpeak button {
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    padding: 0;
    border: none;
    border-radius: 15px;
    background-color: silver;
    text-indent: -9999px;
  }
  .slick-dotsAluminaiSpeak li.slick-active button {
    background-color: #f8c300;
    width: 1.5rem !important;
    height: 0.5rem;
  } */
.slick-dots {
  display: none !important;
}
</style>
