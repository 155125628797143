import { createRouter, createWebHistory } from "vue-router";

import LpView from "@/views/LpView.vue";
import LpViewThankyou from "@/views/LpViewThankyou.vue";
import mbaView from "@/views/mbaView.vue";
import mbaThankyouView from "@/views/mbaThankyouView.vue";

const routes = [
  {
    path: "/",
    redirect: "/lp/degree-programs",
  },
  {
    path: "/lp/degree-programs",
    name: "LpView",
    component: LpView,
  },
  {
    path: "/lp/degree-programs/thankyou",
    name: "LpThankyou",
    component: LpViewThankyou,
  },
  {
    path: "/lp/mba",
    name: "lpMba",
    component: mbaView,
  },
  {
    path: "/lp/mba/thank-you",
    name: "lpMbaThankyou",
    component: mbaThankyouView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
