<template>
  <div>
    <div class="lg:w-[95%] 2xl:w-[70%] mx-auto">
      <div class="px-3 md:px-4">
        <div
          class="flex flex-col lg:flex-row gap-2 justify-between md:gap-9 bg-gradient-to-b from-[#0f2477] to-[#28448d] text-white py-4 px-2 md:px-[50px]"
        >
          <div class="flex items-center gap-2">
            <img src="@/assets/mba/ItAllAddsUpicon2.webp" alt="" srcset="" />
            <p class="text-sm">Program Fee Starting at INR 80,000/-*</p>
          </div>
          <div class="flex items-center gap-2">
            <img src="@/assets/mba/ItAllAddsUpicon1.webp" alt="" />
            <p class="text-sm">Free Access to Paid Linkedin learning</p>
          </div>
        </div>
      </div>
    </div>
    <section
      class="lg:w-[95%] 2xl:w-[70%] mx-auto px-5 md:px-4 py-3 md:py-6 lg:py-8"
    >
      <h3
        class="text-[24px] md:text-[35px] font-semibold text-center capitalize"
      >
        About Our Online MBA Degree Program
      </h3>
      <div>
        <div>
          <p
            :class="[
              isExpandedDescription
                ? 'text-center flex flex-col gap-2 font-medium'
                : 'text-center line-clamp-3 md:line-clamp-2',
            ]"
            class="pt-2"
          >
            An Online MBA is a great choice for those who wish to advance their
            careers in business and management. This degree opens up a plethora
            of career opportunities, increases your earning potential, helps you
            gain technical and management skills, and grows your professional
            network. Our Online MBA is a two-year degree program that offers
            foundation courses, core courses, electives, and a comprehensive
            master thesis intermediary, apart from an option to pursue a
            cross-functional and open elective. The flexibility of the program
            not only allows the learners to learn on weekends but also from
            anywhere and anytime, at their own pace and comfort. It also gives
            them the opportunity to learn while working.
          </p>
          <button @click="toggleReadMore" class="font-bold">
            {{ isExpandedDescription ? "Read less..." : "Read more..." }}
          </button>
        </div>
      </div>
      <!-- other program elective offered -->
      <div>
        <div class="py-2 hidden md:block">
          <h4 class="text-[24px] font-semibold text-center hidden md:block">
            Electives offered
          </h4>
          <div class="flex flex-col lg:flex-row lg:flex-wrap gap-4 py-2">
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Human Resource Management
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Finance
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Marketing
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                General Management
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Systems and Operations Management
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Finance and Marketing
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Human Resource Management and Finance
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Marketing and Human Resource Management
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Project Management
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Information Technology Management
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Healthcare Management
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Logistics & Supply Chain Management
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Business Intelligence & Analytics
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Entrepreneurship and Leadership
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                International Finance (Accredited by ACCA & UK)
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                International Business Management
              </li>
            </ul>
            <!-- <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Sports Management
              </li>
            </ul> -->
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Data Science & Analytics
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Digital Marketing & E-commerce
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Digital Finance
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Integrated Marketing
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Strategic HRM
              </li>
            </ul>
            <ul>
              <li class="flex items-start gap-3 lg:w-[350px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Banking & Finance
              </li>
            </ul>
          </div>
        </div>
        <div class="py-2 md:hidden">
          <h4 class="text-[24px] font-semibold text-center hidden md:block">
            Electives offered
          </h4>
          <div class="flex flex-col lg:flex-row lg:flex-wrap gap-4 py-2">
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Human Resource Management
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Finance
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Marketing
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                General Management
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Systems and Operations Management
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Finance and Marketing
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Human Resource Management and Finance
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Marketing and Human Resource Management
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Project Management
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Information Technology Management
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Healthcare Management
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Logistics & Supply Chain Management
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Business Intelligence & Analytics
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Entrepreneurship and Leadership
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                International Finance (Accredited by ACCA & UK)
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                International Business Management
              </li>
            </ul>
            <!-- <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Sports Management
              </li>
            </ul> -->
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Data Science & Analytics
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Digital Marketing & E-commerce
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Digital Finance
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Integrated Marketing
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Strategic HRM
              </li>
            </ul>
            <ul
            >
              <li class="flex items-start gap-3 lg:w-[400px] text-left">
                <img
                  src="@/assets/mba/electives-offered-icon.webp"
                  alt="Checked-icon"
                />
                Banking & Finance
              </li>
            </ul>
            <!-- <div class="flex justify-center">
              <button
                v-if="
                  degree.program_list.length > 8 &&
                  degree.program_list.length > end
                "
                @click="viewMore"
                class="text-[#2e4c8c] text-[16px] text-center button-cta"
              >
                Load More
              </button>
              <button
                v-if="
                  degree.program_list.length <= end &&
                  degree.program_list.length > 8
                "
                @click="viewLess"
                class="text-[#2e4c8c] text-[16px] text-center button-cta"
              >
                Load Less
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </section>
    <!-- eligibility -->

    <div
      class="lg:w-[95%] 2xl:w-[70%] mx-auto px-5 md:px-4 py-3 flex flex-col gap-3"
    >
      <h4
        class="text-[18px] lg:text-[20px] text-left font-semibold"
      >Eligibility</h4>
      <div
        class="text-[14px] text-left eligibility"
      >Pass in an Undergraduate Program of a minimum duration of three (3) years in any stream from a UGC Recognized University, with a minimum aggregate of 50% or an equivalent letter/numerical grade.</div>
      <div
        class="text-[14px] text-left eligibility"
      >A relaxation of 5% shall be given to SC/ST candidates.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OnlineDegreeView",
  data() {
    return {
      start: 0,
      end: 8,
      isExpandedDescription: false,
      activeTab: "MBA",
      activeUg: "BBA",
      activeTabAcca: "MBA",

      electiveStaticData: [
        {
          title: "MBA",
          description:
            "<p> An Online MBA is the best choice for those who wish to advance their careers in business and management. This degree opens up a plethora of career opportunities, increases your earning potential, helps you gain technical and management skills, and grows your professional network.</p> <br> <p>Our Online MBA is a two-year degree program that offers foundation courses, core courses, electives, and a comprehensive master thesis intermediary, apart from an option to pursue a cross-functional and open elective. The flexibility of the program not only allows the learners to learn on weekends but also from anywhere and anytime, at their own pace and comfort. It also gives them the opportunity to learn while working, thus making the program suitable for both students and working professionals. </p>",
          Offered: [
            "Human Resource Management",
            "Finance",
            "Marketing",
            "General Management",
            "Systems and Operations Management",
            "Finance and Marketing",
            "Human Resource Management and Finance",
            "Marketing and Human Resource Management",
            "Project Management",
            "Information Technology Management",
            "Healthcare Management",
            "Logistics & Supply Chain Management ",
            "Business Intelligence & Analytics ",
            "Entrepreneurship and Leadership",
            "International Business Management",
            // "Sports Management",
            "Data Science & Analytics",
            "Digital Marketing & E-commerce",
            "Digital Finance",
            "Integrated Marketing",
            "International Finance (Accredited by ACCA & UK)",
            "Strategic HRM",
            "Banking & Finance ",
          ],
        },
        {
          title: "MCA",
          description:
            "<p>Our Online MCA is a two-year degree program that offers foundation courses, core courses, and a comprehensive project, apart from an option to pursue a cross-functional and open elective. <br> The core programs incorporate AICTE's model curriculum as prescribed for a full-time Online MCA Program. They have been designed keeping in mind the industry's requirements to provide in-depth knowledge of the concepts and technologies required to develop software applications. Our Online MCA curriculum is intended to increase a learner's technical skills and abilities, thus offering a way to accelerate one's career for corporate roles in the IT domain. The program is engineered in a way that caters to both students and working professionals, instilling in them core technical abilities as they master the tech domain.</p>",
          Offered: [
            "Computer Science & IT",
            "Cyber Security ",
            "Data Analytics",
            "Machine Learning",
            "Full Stack Development",
            "Cloud Computing",
            "Data Science",
            "Artificial Intelligence",
          ],
        },
        {
          title: "M.Com",
          description:
            "<p>The Online M.Com (Master of Commerce) is a two-year degree program that gives you a comprehensive understanding of various professional streams like accounting, finance, taxation, banking, insurance, etc. Whether you're pursuing this program from India or elsewhere, having an understanding of commerce and its intricacies will benefit you greatly in the long run if you are thinking of entering the world of commerce. <br> Along with functional and domain expertise, the Online M.Com Program will also equip you with the competencies and skills required to boost your career into leadership and managerial roles. The unique component of a research project in your curriculum requires you to conduct in-depth research in your area of interest as it will allow you to practice your learnings. You will also be encouraged to publish your research with the support of a mentor.</p>",
          Offered: [
            "Strategic Accounting & Finance (Accredited by CPA & US)",
            "International Finance (Accredited by ACCA & UK)",
            "Accounting & Finance",
          ],
        },
        {
          title: "MA",
          description:
            "<p>An Online MA Program is the best choice for those who wish to deepen their understanding and expertise in a specific field within the arts and humanities. Learners delve into advanced theoretical concepts, critical analysis, and research methodologies relevant to their chosen discipline. Through rigorous coursework and case studies, they develop refined communication, creativity, and problem-solving skills, preparing them for careers in academia, research, cultural institutions, or various industries requiring a nuanced understanding of human expression and culture.</p>",
          Offered: ["English", "Economics", "Public Policy"],
        },
        {
          title: "BBA",
          description:
            "<p>Our Online BBA Program is a three-year degree that prepares you for entry-level roles in your chosen area of electives and takes you through the specific needs of a business. It will give you a comprehensive understanding of emerging business trends and technologies and an in-depth study of the inner workings of an organization. <br> We have designed the Online BBA Program to provide you with a broad overview of all essentials of business and management while providing a deep dive into the functional area of electives. Along with functional and domain expertise, the Online BBA Degree will also equip you with the competencies and skills required to kickstart your career in various business and management roles.</p>",
          Offered: ["Human Resource Management", "Finance", "Marketing"],
        },
        {
          title: "B.Com",
          description:
            "<p>The complexity of business operations today demands a blend of accounting and finance expertise. Our Online B.Com (Bachelor of Commerce) is a three-year degree program that will prepare you for a broad spectrum of managerial skills and understanding streams like finance, accounting, economics, law, insurance, management, taxation, etc. <br> Along with functional and domain expertise, the Online B.Com Degree Program will also equip you with the competencies and skills required to advance your career into leadership and strategic roles, giving you that necessary edge to compete and succeed.</p>",
          Offered: [
            "International Finance & Accounting (Accredited by ACCA & UK)",
          ],
        },
      ],
      bachelors: [
        {
          title: "BBA",
          description:
            "<p>Our Online BBA Program is a three-year degree that prepares you for entry-level roles in your chosen area of electives and takes you through the specific needs of a business. It will give you a comprehensive understanding of emerging business trends and technologies and an in-depth study of the inner workings of an organization. <br> We have designed the Online BBA Program to provide you with a broad overview of all essentials of business and management while providing a deep dive into the functional area of electives. Along with functional and domain expertise, the Online BBA Degree will also equip you with the competencies and skills required to kickstart your career in various business and management roles.</p>",
          Offered: ["Human Resource Management", "Finance", "Marketing"],
        },
        {
          title: "B.Com",
          description:
            "<p>The complexity of business operations today demands a blend of accounting and finance expertise. Our Online B.Com (Bachelor of Commerce) is a three-year degree program that will prepare you for a broad spectrum of managerial skills and understanding streams like finance, accounting, economics, law, insurance, management, taxation, etc. Along with functional and domain expertise, the Online B.Com Degree Program will also equip you with the competencies and skills required to advance your career into leadership and strategic roles, giving you that necessary edge to compete and succeed.</p>",
          Offered: [
            "International Finance & Accounting (Accredited by ACCA & UK)",
          ],
        },
      ],
      masters: [
        {
          title: "MBA",
          description:
            "<p> An Online MBA is the best choice for those who wish to advance their careers in business and management. This degree opens up a plethora of career opportunities, increases your earning potential, helps you gain technical and management skills, and grows your professional network. <br> Our Online MBA is a two-year degree program that offers foundation courses, core courses, electives, and a comprehensive master thesis intermediary, apart from an option to pursue a cross-functional and open elective. The flexibility of the program not only allows the learners to learn on weekends but also from anywhere and anytime, at their own pace and comfort. It also gives them the opportunity to learn while working, thus making the program suitable for both students and working professionals. </p>",
          Offered: [
            "Human Resource Management",
            "Finance",
            "Marketing",
            "General Management",
            "Systems and Operations Management",
            "Finance and Marketing",
            "Human Resource Management and Finance",
            "Marketing and Human Resource Management",
            "Project Management",
            "Information Technology Management",
            "Healthcare Management",
            "Logistics & Supply Chain Management ",
            "Business Intelligence & Analytics ",
            "Entrepreneurship and Leadership",
            "International Business Management",
            // "Sports Management",
            "Data Science & Analytics",
            "Digital Marketing & E-commerce",
            "Digital Finance",
            "Integrated Marketing",
            "Strategic HRM",
            "Banking & Finance ",
            "International Finance (Accredited by ACCA & UK)",
          ],
        },
        {
          title: "MCA",
          description:
            "<p>Our Online MCA is a two-year degree program that offers foundation courses, core courses, and a comprehensive project, apart from an option to pursue a cross-functional and open elective. <br> The core programs incorporate AICTE's model curriculum as prescribed for a full-time Online MCA Program. They have been designed keeping in mind the industry's requirements to provide in-depth knowledge of the concepts and technologies required to develop software applications. Our Online MCA curriculum is intended to increase a learner's technical skills and abilities, thus offering a way to accelerate one's career for corporate roles in the IT domain. The program is engineered in a way that caters to both students and working professionals, instilling in them core technical abilities as they master the tech domain.</p>",
          Offered: [
            "Computer Science & IT",
            "Cyber Security ",
            "Data Analytics",
            "Machine Learning",
            "Full Stack Development",
            "Cloud Computing",
            "Data Science",
            "Artificial Intelligence",
          ],
        },
        {
          title: "M.Com",
          description:
            "<p>The Online M.Com (Master of Commerce) is a two-year degree program that gives you a comprehensive understanding of various professional streams like accounting, finance, taxation, banking, insurance, etc. Whether you're pursuing this program from India or elsewhere, having an understanding of commerce and its intricacies will benefit you greatly in the long run if you are thinking of entering the world of commerce. <br> Along with functional and domain expertise, the Online M.Com Program will also equip you with the competencies and skills required to boost your career into leadership and managerial roles. The unique component of a research project in your curriculum requires you to conduct in-depth research in your area of interest as it will allow you to practice your learnings. You will also be encouraged to publish your research with the support of a mentor.</p>",
          Offered: [
            "Strategic Accounting & Finance (Accredited by CPA & US)",
            "International Finance (Accredited by ACCA & UK)",
            "Accounting & Finance",
          ],
        },
      ],
      accaDatas: ["MBA", "M.Com"],
      mba: [
        {
          subTitle:
            "About our Online MBA Degree Program in International Finance (Accredited by ACCA, UK)",
          description:
            "<p>This two-year Online MBA in International Finance is integrated with the ACCA (Association of Chartered Certified Accountants) qualification  and is also accredited by ACCA by granting  the highest number of exemptions. The program meets the highest academic and quality  standards as it has been designed based on present-day industry requirements.It is suitable for  individuals looking to launch an international career or gain competency in dealing with international investment and financing  transactions, international payments, and exchange rates. The MBA International Finance Program provides necessary tools for learners  to navigate the multinational financial system, understand international portfolio management, and explore the balance of payments  and international economic linkages. The Online MBA in International Finance is intended to enhance learners'  knowledge of global finance, thus accelerating their careers in key corporate roles in the finance domain.</p>",
        },
      ],
      mcom: [
        {
          subTitle:
            "About our Online M.Com Degree Program in International Finance (Accredited by ACCA, UK)",
          description:
            "<p>This two-year Online M.Com in International Finance is integrated with the ACCA (Association of Chartered Certified Accountants) qualification  and is also accredited by ACCA by granting the highest number of exemptions.The M.Com in International Finance program is focused on developing individuals with expertise and applied worldwide capabilities in risk management,  wealth management, and strategic financial management to fulfill the requirements of both academia and business today. The Online M.Com in International Finance Degree is intended to enhance learners' critical thinking and problem-solving skills required  to comprehend and assess important financial challenges in the national and international business world, thus accelerating  their careers across key corporate roles in the finance domain.</p>",
        },
      ],
    };
  },

  methods: {
    getDescription(title) {
      return (
        this.electiveStaticData.find((item) => item.title === title)
          ?.description || ""
      );
    },
    getOffered(title) {
      return (
        this.electiveStaticData.find((item) => item.title === title)?.Offered ||
        []
      );
    },
    toggleReadMore() {
      this.isExpandedDescription = !this.isExpandedDescription;
    },

    viewMore() {
      this.end += 8;
    },
    viewLess() {
      this.end = 8;
    },
  },
};
</script>

<style scoped>
.eligibility li {
  padding-left: 20px;
  background: url(@/assets/mba/pointer.webp) left 1px no-repeat;

  background-size: 14px;
  margin-bottom: 12px;
  list-style-type: none !important;
}
.eligibility ul {
  padding-left: 0px;
}
</style>
