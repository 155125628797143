<template>
  <div>
    <banner />
    <onlinedegreeVue />
    <itsalladdupVue />
    <makedreamVue />
    <alumnaiSpeakVue />
    <aluminaiWorkVue />
    <AddmissionProcess />
    <faqViewVue />
    <SocialMedia />
    <footerView />
  </div>
</template>

<script>
import banner from "@/components/mba/banner.vue";
import onlinedegreeVue from "../components/mba/onlinedegree.vue";
import itsalladdupVue from "../components/mba/itsalladdup.vue";
import makedreamVue from "../components/mba/makedream.vue";
import alumnaiSpeakVue from "../components/mba/alumnaiSpeak.vue";
import aluminaiWorkVue from "../components/mba/aluminaiWork.vue";
import AddmissionProcess from "../components/mba/admissonProcess.vue";
import faqViewVue from '../components/mba/faqView.vue';
import SocialMedia from "../components/lpdesign/socialmedia.vue"
import footerView from "../components/mba/footer.vue"
export default {
  name: "mbaView",
  components: {
    banner,
    onlinedegreeVue,
    itsalladdupVue,
    makedreamVue,
    alumnaiSpeakVue,
    aluminaiWorkVue,
    AddmissionProcess,
    faqViewVue,
    SocialMedia,
    footerView
  },
};
</script>

<style scoped></style>
