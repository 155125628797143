<template>
  <div>
    <bannerVue />
    <degree />
    <itsAlladdsUpVue />
    <dreamVue />
    <aluminaiSpeakVue />
    <aluminaWorkVue />
    <admissionViewVue />
    <faqViewVue />
    <footerViewVue />
    <socialmedia />
  </div>
</template>

<script>
import bannerVue from "@/components/lpdesign/banner.vue";
import degree from "@/components/lpdesign/degree.vue";
import itsAlladdsUpVue from "@/components/lpdesign/itsAlladdsUp.vue";
import dreamVue from "@/components/lpdesign/dream.vue";
import aluminaiSpeakVue from "@/components/lpdesign/aluminaiSpeak.vue";
import aluminaWorkVue from "@/components/lpdesign/aluminaWork.vue";
import admissionViewVue from "@/components/lpdesign/admissionView.vue";
import faqViewVue from "@/components/lpdesign/faqView.vue";
import footerViewVue from '@/components/lpdesign/footerView.vue';
import socialmedia from '@/components/lpdesign/socialmedia.vue';
export default {
  name: "lpView",
  components: {
    bannerVue,
    degree,
    itsAlladdsUpVue,
    dreamVue,
    aluminaiSpeakVue,
    aluminaWorkVue,
    admissionViewVue,
    faqViewVue,
    footerViewVue,
    socialmedia
  },
};
</script>

<style scoped></style>
