<template>
    <div>
      <section>
        <div class="w-full mx-auto max-w-7xl 2xl:max-w-screen-2xl">
          <!-- Desktop -->
          <div class="hidden md:block mt-[1rem] mb-[1rem] md:px-5">
            <div class="flex justify-center items-center">
              <div class="w-1/2 p-4">
                <h2
                  class="text-black font-medium text-left md:text-xl lg:text-3xl"
                >
                  Make Your Dreams a
                  <br />
                  Reality with Us
                </h2>
                <p
                  class="font-bold text-lg lg:text-2xl text-left text-black pt-4"
                >
                  Degrees from JAIN Online are:
                </p>
                <ul class="text-left pt-5 text-black text-[18px]">
                  <li class="flex gap-2  text-base lg:text-xl">
                    <img
                      src="@/assets/lpOnline/electives-offered-icon.webp"
                      class="w-4 h-4 mt-2"
                      alt="Check-icon"
                    />
                    Equivalent to JAIN (Deemed-to-be University) on-campus degrees
                  </li>
                  <li class="flex gap-2 items-center text-base lg:text-xl mt-4">
                    <img
                      src="@/assets/lpOnline/electives-offered-icon.webp"
                      class="w-4 h-4"
                      alt="Check-icon"
                    />
                    Globally recognized
                  </li>
                </ul>
              </div>
              <div class="relative p-2">
                <div
                  class="bg-[#001C54] w-[400px] lg:w-[430px] 2xl:w-[550px] h-[300px] 2xl:h-[350px] rounded-3xl"
                >
                  <img
                    src="@/assets/lpOnline/JAIN-Online-degree-certificate.webp"
                    alt="sample-certificate"
                    class="absolute top-0 left-7 w-[350px] lg:w-[400px] 2xl:w-[500px] h-full p-10 rounded-[50px]"
                  />
                </div>
              </div>
            </div>
          </div>
  
          <!-- Desktop -->
  
          <!-- Mobile -->
          <div class="md:hidden mb-[1rem]">
            <div class="w-full mx-auto">
              <h4 class="text-center px-4 text-black font-semibold text-2xl">
                Make Your Dreams a
                <br />
                Reality with Us
              </h4>
              <div class="w-[90%] mx-auto">
                <div class="bg-[#001C54] h-[230px] m-3 rounded-3xl">
                  <img
                    src="@/assets/lpOnline/JAIN-Online-degree-certificate.webp"
                    alt="sample-certificate"
                    class="w-full h-full px-10 py-5"
                  />
                </div>
              </div>
              <div class="w-[90%] mx-auto">
                <p class="text-center text-black font-semibold text-[16px] mt-2">
                  Degrees from JAIN Online are:
                </p>
                <ul class="text-black pt-3 text-left">
                  <li class="mt-3 ml-5 flex gap-2 items-start">
                    <img
                      src="@/assets/lpOnline/electives-offered-icon.webp"
                      class="w-4 h-4"
                      alt="Check-icon"
                    />
                    Equivalent to JAIN (Deemed-to-be University)
                    <br />
                    on-campus degrees
                  </li>
                  <li class="mt-3 ml-5 flex gap-2 items-start">
                    <img
                      src="@/assets/lpOnline/electives-offered-icon.webp"
                      class="w-4 h-4"
                      alt="Check-icon"
                    />
                    Globally recognized
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Mobile -->
        </div>
      </section>
    </div>
  </template>
  <script>
  export default {
    name: 'dreamView',
  }
  </script>
  <style lang=""></style>