<template>
  <div>
    <div class="lg:w-[95%] 2xl:w-[70%] mx-auto">
      <div class="px-3 md:px-4">
        <div
          class="flex flex-col lg:flex-row gap-2 justify-between md:gap-9 bg-gradient-to-b from-[#0f2477] to-[#28448d] text-white py-4 px-2 md:px-[50px]"
        >
          <div class="flex items-center gap-2">
            <img
              src="@/assets/lpOnline/ItAllAddsUpicon2.webp"
              alt=""
              srcset=""
            />
            <p class="text-sm">Program Fee Starting at INR 61,000/-</p>
          </div>
          <div class="flex items-center gap-2">
            <img src="@/assets/lpOnline/ItAllAddsUpicon1.webp" alt="" />
            <p class="text-sm">Free Access to Paid LInkedin learning</p>
          </div>
        </div>
      </div>
    </div>
    <section
      class="lg:w-[95%] 2xl:w-[70%] mx-auto px-5 md:px-4 py-3 md:py-6 lg:py-8"
    >
      <h3
        class="text-[24px] md:text-[35px] font-semibold text-center capitalize"
      >
        About Online Degree Programs
      </h3>
      <div>
        <!-- <div
            class=""
            v-if="!['online', 'pg', 'ug'].includes(degree.slug)"
          >
            <p
              v-html="degree.program_description"
              :class="[
                isExpandedDescription
                  ? 'text-center flex flex-col gap-2 font-medium'
                  : 'text-center line-clamp-3 md:line-clamp-2',
              ]"
              class="pt-2"
            ></p>
            <button @click="toggleReadMore" class="">
              {{ isExpandedDescription ? 'Read less...' : 'Read more...' }}
            </button>
          </div> -->
      </div>
      <!-- other program elective offered -->
      <!-- <div v-if="!['online', 'pg', 'ug', 'acca'].includes(degree.slug)">
          <div v-if="degree.program_list" class="py-2 hidden md:block">
            <h4 class="text-[24px] font-semibold text-center hidden md:block">
              Electives offered
            </h4>
            <div class="flex flex-col lg:flex-row lg:flex-wrap gap-4 py-2">
              <ul v-for="elective in degree.program_list" :key="elective">
                <li class="flex items-start gap-3 lg:w-[350px] text-left">
                  <img
                    src="@/assets/lpOnline/electives-offered-icon.webp"
                    alt="Checked-icon"
                  />
                  {{ elective }}
                </li>
              </ul>
            </div>
          </div>
          <div class="py-2 md:hidden">
            <h4 class="text-[24px] font-semibold text-center hidden md:block">
              Electives offered
            </h4>
            <div class="flex flex-col lg:flex-row lg:flex-wrap gap-4 py-2">
              <ul
                v-for="elective in degree.program_list.slice(start, end)"
                :key="elective"
              >
                <li class="flex items-start gap-3 lg:w-[400px] text-left">
                  <img
                    src="@/assets/lpOnline/electives-offered-icon.webp"
                    alt="Checked-icon"
                  />
                  {{ elective }}
                </li>
              </ul>
              <div class="flex justify-center">
                <button
                  v-if="
                    degree.program_list.length > 8 &&
                    degree.program_list.length > end
                  "
                  @click="viewMore"
                  class="text-[#2e4c8c] text-[16px] text-center button-cta"
                >
                  Load More
                </button>
                <button
                  v-if="
                    degree.program_list.length <= end &&
                    degree.program_list.length > 8
                  "
                  @click="viewLess"
                  class="text-[#2e4c8c] text-[16px] text-center button-cta"
                >
                  Load Less
                </button>
              </div>
            </div>
          </div>
        </div> -->
    </section>
    <!-- online degree  -->
    <div>
      <section class="lg:w-[95%] 2xl:w-[70%] mx-auto px-5 md:px-4">
        <div>
          <div class="flex justify-center gap-3">
            <div
              v-for="(electiveStatic, index) in electiveStaticData"
              :key="index"
              @click="activeTab = electiveStatic.title"
              :class="{
                'border-b-[6px] border-black':
                  activeTab === electiveStatic.title,
                '': activeTab !== electiveStatic.title,
              }"
            >
              <button
                class="capitalize text-lg md:text-2xl px-[7px] py-[8px] md:px-[10px] md:py-[16px] text-black font-semibold"
              >
                {{ electiveStatic.title }}
              </button>
            </div>
          </div>
        </div>
        <div class="py-3">
          <div class="">
            <p
              v-html="getDescription(activeTab)"
              :class="[
                isExpandedDescription
                  ? 'text-center flex flex-col gap-2 font-medium'
                  : 'text-center line-clamp-3 md:line-clamp-2',
              ]"
              class=""
            ></p>
            <button
              @click="toggleReadMore"
              class="text-[#001c54] font-semibold"
            >
              {{ isExpandedDescription ? "Read less..." : "Read more..." }}
            </button>
          </div>

          <div class="py-2 hidden md:block">
            <h4 class="text-[24px] font-semibold text-center hidden md:block">
              Electives offered
            </h4>
            <div class="flex flex-col lg:flex-row lg:flex-wrap gap-4 py-2">
              <ul
                v-for="offered in getOffered(activeTab)"
                :key="offered"
                class=""
              >
                <li class="flex items-start gap-3 lg:w-[380px] text-left">
                  <img
                    src="@/assets/lpOnline/electives-offered-icon.webp"
                    alt="Checked-icon"
                  />
                  {{ offered }}
                </li>
              </ul>
            </div>
          </div>
          <!-- mobile description -->
          <div class="py-2 md:hidden">
            <h4 class="text-[24px] font-semibold text-center hidden md:block">
              Electives offered
            </h4>
            <div class="flex flex-col lg:flex-row lg:flex-wrap gap-4 py-2">
              <ul
                v-for="offered in getOffered(activeTab).slice(start, end)"
                :key="offered"
                class=""
              >
                <li class="flex items-start gap-3 lg:w-[350px] text-left">
                  <img
                    src="@/assets/lpOnline/electives-offered-icon.webp"
                    alt="Checked-icon"
                  />
                  {{ offered }}
                </li>
              </ul>
              <div class="flex justify-center">
                <button
                  v-if="
                    getOffered(activeTab).length > 8 &&
                    getOffered(activeTab).length > end
                  "
                  @click="viewMore"
                  class="text-[#2e4c8c] text-[16px] text-center button-cta flex items-center gap-2"
                >
                  Load More
                  <img
                    src="@/assets/lpOnline/loadmore_360.webp"
                    alt="loadmore-icon"
                    class="w-3"
                  />
                </button>
                <button
                  v-if="
                    getOffered(activeTab).length < end &&
                    getOffered(activeTab).length > 8
                  "
                  @click="viewLess"
                  class="text-[#2e4c8c] text-[16px] text-center button-cta flex items-center gap-2"
                >
                  Load Less
                  <img
                    src="@/assets/lpOnline/loadmore_360.webp"
                    alt="loadmore-icon"
                    class="w-3 rotate-180"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- eligibility -->

    <div
      class="lg:w-[95%] 2xl:w-[70%] mx-auto px-5 md:px-4 py-3 flex flex-col gap-3"
    >
      <h4 class="text-[18px] lg:text-[20px] text-left font-semibold">
        Eligibility
      </h4>
      <div class="text-[14px] text-left eligibility">
        Pass in an Undergraduate Program of a minimum duration of three (3)
        years in any stream from a UGC Recognized University, with a minimum
        aggregate of 50% or an equivalent letter/numerical grade.
      </div>
      <div class="text-[14px] text-left eligibility">
        A relaxation of 5% shall be given to SC/ST candidates.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "degreeViews",
  props: ["degree"],

  data() {
    return {
      start: 0,
      end: 8,
      isExpandedDescription: false,
      activeTab: "MBA",
      activeUg: "BBA",
      activeTabAcca: "MBA",

      electiveStaticData: [
        {
          title: "MBA",
          description:
            "<p> An Online MBA is the best choice for those who wish to advance their careers in business and management. This degree opens up a plethora of career opportunities, increases your earning potential, helps you gain technical and management skills, and grows your professional network.</p>  <p>Our Online MBA is a two-year degree program that offers foundation courses, core courses, electives, and a comprehensive master thesis intermediary, apart from an option to pursue a cross-functional and open elective. The flexibility of the program not only allows the learners to learn on weekends but also from anywhere and anytime, at their own pace and comfort. It also gives them the opportunity to learn while working, thus making the program suitable for both students and working professionals. </p>",
          Offered: [
            "Human Resource Management",
            "Finance",
            "Marketing",
            "General Management",
            "Systems and Operations Management",
            "Finance and Marketing",
            "Human Resource Management and Finance",
            "Marketing and Human Resource Management",
            "Project Management",
            "Information Technology Management",
            "Healthcare Management",
            "Logistics & Supply Chain Management ",
            "Business Intelligence & Analytics ",
            "Entrepreneurship and Leadership",
            "International Finance (Accredited by ACCA, UK)",
            "International Business Management",
            // "Sports Management",
            "Retail Banking Operations",
          ],
        },
        {
          title: "MCA",
          description:
            "<p>Our Online MCA is a two-year degree program that offers foundation courses, core courses, and a comprehensive project, apart from an option to pursue a cross-functional and open elective. The core programs incorporate AICTE's model curriculum as prescribed for a full-time Online MCA Program.</P> <p> They have been designed keeping in mind the industry's requirements to provide in-depth knowledge of the concepts and technologies required to develop software applications. Our Online MCA curriculum is intended to increase a learner's technical skills and abilities, thus offering a way to accelerate one's career for corporate roles in the IT domain. The program is engineered in a way that caters to both students and working professionals, instilling in them core technical abilities as they master the tech domain.</p>",
          Offered: [
            "Computer Science & IT",
            "Cyber Security ",
            "Data Analytics",
          ],
        },
        {
          title: "M.Com",
          description:
            "<p>The Online M.Com (Master of Commerce) is a two-year degree program that gives you a comprehensive understanding of various professional streams like accounting, finance, taxation, banking, insurance, etc. Whether you're pursuing this program from India or elsewhere, having an understanding of commerce and its intricacies will benefit you greatly in the long run if you are thinking of entering the world of commerce.</p>  <p>Along with functional and domain expertise, the Online M.Com Program will also equip you with the competencies and skills required to boost your career into leadership and managerial roles. The unique component of a research project in your curriculum requires you to conduct in-depth research in your area of interest as it will allow you to practice your learnings. You will also be encouraged to publish your research with the support of a mentor.</p>",
          Offered: [
            "International Finance (Accredited by ACCA, UK)",
            "Accounting & Finance",
          ],
        },
        {
          title: "MA",
          description:
            "<p>An Online MA Program is the best choice for those who wish to deepen their understanding and expertise in a specific field within the arts and humanities. Learners delve into advanced theoretical concepts, critical analysis, and research methodologies relevant to their chosen discipline.</p> <p> Through rigorous coursework and case studies, they develop refined communication, creativity, and problem-solving skills, preparing them for careers in academia, research, cultural institutions, or various industries requiring a nuanced understanding of human expression and culture.</p>",
          Offered: [
            "English",
            "Economics",
            "Public Policy",
            "Journalism and Mass Communication",
          ],
        },
        {
          title: "BBA",
          description:
            "<p>Our Online BBA Program is a three-year degree that prepares you for entry-level roles in your chosen area of electives and takes you through the specific needs of a business. It will give you a comprehensive understanding of emerging business trends and technologies and an in-depth study of the inner workings of an organization.</p> <p> We have designed the Online BBA Program to provide you with a broad overview of all essentials of business and management while providing a deep dive into the functional area of electives. Along with functional and domain expertise, the Online BBA Degree will also equip you with the competencies and skills required to kickstart your career in various business and management roles.</p>",
          Offered: [
            "Human Resource Management",
            "Finance",
            "Marketing",
            "Engineering and Project Management",
            "Healthcare Management",
          ],
        },
        {
          title: "B.Com",
          description:
            "<p>The complexity of business operations today demands a blend of accounting and finance expertise. Our Online B.Com (Bachelor of Commerce) is a three-year degree program that will prepare you for a broad spectrum of managerial skills and understanding streams like finance, accounting, economics, law, insurance, management, taxation, etc.</p> <p> Along with functional and domain expertise, the Online B.Com Degree Program will also equip you with the competencies and skills required to advance your career into leadership and strategic roles, giving you that necessary edge to compete and succeed.</p>",
          Offered: [
            "Accounting & Finance",
            "Corporate Accounting",
            "International Finance & Accounting (Accredited by ACCA, UK)",
            "International Finance (Accredited by ACCA, UK)",
          ],
        },
      ],
      bachelors: [
        {
          title: "BBA",
          description:
            "<p>Our Online BBA Program is a three-year degree that prepares you for entry-level roles in your chosen area of electives and takes you through the specific needs of a business. It will give you a comprehensive understanding of emerging business trends and technologies and an in-depth study of the inner workings of an organization. <br> We have designed the Online BBA Program to provide you with a broad overview of all essentials of business and management while providing a deep dive into the functional area of electives. Along with functional and domain expertise, the Online BBA Degree will also equip you with the competencies and skills required to kickstart your career in various business and management roles.</p>",
          Offered: ["Human Resource Management", "Finance", "Marketing"],
        },
        {
          title: "B.Com",
          description:
            "<p>The complexity of business operations today demands a blend of accounting and finance expertise. Our Online B.Com (Bachelor of Commerce) is a three-year degree program that will prepare you for a broad spectrum of managerial skills and understanding streams like finance, accounting, economics, law, insurance, management, taxation, etc. Along with functional and domain expertise, the Online B.Com Degree Program will also equip you with the competencies and skills required to advance your career into leadership and strategic roles, giving you that necessary edge to compete and succeed.</p>",
          Offered: [
            "International Finance & Accounting (Accredited by ACCA & UK)",
          ],
        },
      ],
      masters: [
        {
          title: "MBA",
          description:
            "<p> An Online MBA is the best choice for those who wish to advance their careers in business and management. This degree opens up a plethora of career opportunities, increases your earning potential, helps you gain technical and management skills, and grows your professional network. <br> Our Online MBA is a two-year degree program that offers foundation courses, core courses, electives, and a comprehensive master thesis intermediary, apart from an option to pursue a cross-functional and open elective. The flexibility of the program not only allows the learners to learn on weekends but also from anywhere and anytime, at their own pace and comfort. It also gives them the opportunity to learn while working, thus making the program suitable for both students and working professionals. </p>",
          Offered: [
            "Human Resource Management",
            "Finance",
            "Marketing",
            "General Management",
            "Systems and Operations Management",
            "Finance and Marketing",
            "Human Resource Management and Finance",
            "Marketing and Human Resource Management",
            "Project Management",
            "Information Technology Management",
            "Healthcare Management",
            "Logistics & Supply Chain Management ",
            "Business Intelligence & Analytics ",
            "Entrepreneurship and Leadership",
            "International Business Management",
            // "Sports Management",
            "Data Science & Analytics",
            "Digital Marketing & E-commerce",
            "Digital Finance",
            "Integrated Marketing",
            "Strategic HRM",
            "Banking & Finance ",
            "International Finance (Accredited by ACCA & UK)",
          ],
        },
        {
          title: "MCA",
          description:
            "<p>Our Online MCA is a two-year degree program that offers foundation courses, core courses, and a comprehensive project, apart from an option to pursue a cross-functional and open elective. <br> The core programs incorporate AICTE's model curriculum as prescribed for a full-time Online MCA Program. They have been designed keeping in mind the industry's requirements to provide in-depth knowledge of the concepts and technologies required to develop software applications. Our Online MCA curriculum is intended to increase a learner's technical skills and abilities, thus offering a way to accelerate one's career for corporate roles in the IT domain. The program is engineered in a way that caters to both students and working professionals, instilling in them core technical abilities as they master the tech domain.</p>",
          Offered: [
            "Computer Science & IT",
            "Cyber Security ",
            "Data Analytics",
            "Machine Learning",
            "Full Stack Development",
            "Cloud Computing",
            "Data Science",
            "Artificial Intelligence",
          ],
        },
        {
          title: "M.Com",
          description:
            "<p>The Online M.Com (Master of Commerce) is a two-year degree program that gives you a comprehensive understanding of various professional streams like accounting, finance, taxation, banking, insurance, etc. Whether you're pursuing this program from India or elsewhere, having an understanding of commerce and its intricacies will benefit you greatly in the long run if you are thinking of entering the world of commerce. <br> Along with functional and domain expertise, the Online M.Com Program will also equip you with the competencies and skills required to boost your career into leadership and managerial roles. The unique component of a research project in your curriculum requires you to conduct in-depth research in your area of interest as it will allow you to practice your learnings. You will also be encouraged to publish your research with the support of a mentor.</p>",
          Offered: [
            "Strategic Accounting & Finance (Accredited by CPA & US)",
            "International Finance (Accredited by ACCA & UK)",
            "Accounting & Finance",
          ],
        },
      ],
      accaDatas: ["MBA", "M.Com"],
      mba: [
        {
          subTitle:
            "About our Online MBA Degree Program in International Finance (Accredited by ACCA, UK)",
          description:
            "<p>This two-year Online MBA in International Finance is integrated with the ACCA (Association of Chartered Certified Accountants) qualification  and is also accredited by ACCA by granting  the highest number of exemptions. The program meets the highest academic and quality  standards as it has been designed based on present-day industry requirements.It is suitable for  individuals looking to launch an international career or gain competency in dealing with international investment and financing  transactions, international payments, and exchange rates. The MBA International Finance Program provides necessary tools for learners  to navigate the multinational financial system, understand international portfolio management, and explore the balance of payments  and international economic linkages. The Online MBA in International Finance is intended to enhance learners'  knowledge of global finance, thus accelerating their careers in key corporate roles in the finance domain.</p>",
        },
      ],
      mcom: [
        {
          subTitle:
            "About our Online M.Com Degree Program in International Finance (Accredited by ACCA, UK)",
          description:
            "<p>This two-year Online M.Com in International Finance is integrated with the ACCA (Association of Chartered Certified Accountants) qualification  and is also accredited by ACCA by granting the highest number of exemptions.The M.Com in International Finance program is focused on developing individuals with expertise and applied worldwide capabilities in risk management,  wealth management, and strategic financial management to fulfill the requirements of both academia and business today. The Online M.Com in International Finance Degree is intended to enhance learners' critical thinking and problem-solving skills required  to comprehend and assess important financial challenges in the national and international business world, thus accelerating  their careers across key corporate roles in the finance domain.</p>",
        },
      ],
    };
  },

  methods: {
    getDescription(title) {
      return (
        this.electiveStaticData.find((item) => item.title === title)
          ?.description || ""
      );
    },
    getOffered(title) {
      return (
        this.electiveStaticData.find((item) => item.title === title)?.Offered ||
        []
      );
    },
    toggleReadMore() {
      this.isExpandedDescription = !this.isExpandedDescription;
    },

    viewMore() {
      this.end += 8;
    },
    viewLess() {
      this.end = 8;
    },
  },
};
</script>

<style scoped>
.eligibility li {
  padding-left: 20px;
  background: url(@/assets/lpOnline/pointer.webp) left 1px no-repeat;

  background-size: 14px;
  margin-bottom: 12px;
  list-style-type: none !important;
}
.eligibility ul {
  padding-left: 0px;
}
</style>
