<template>
    <div class="py-5 md:py-6 lg:py-8">
      <div
        class="bg-gradient-to-b from-[#0f3d9b] to-[#062460] w-full py-5 md:py-6 lg:py-10 min-h-[400px]"
      >
        <section class="w-full lg:w-[95%] 2xl:w-[70%]  mx-auto px-5 md:px-4">
          <div class="flex flex-col gap-4">
            <h1 class="text-[20px] lg:text-[30px] text-[#f9c300] text-center">
              #ItAllAddsUp
            </h1>
            <div class="w-full flex flex-col gap-3">
              <h1 class="text-[18px] lg:text-[20px] text-white text-left">
                Quantifiable metrics
              </h1>
              <div class="slick_allAddUp">
                <div
                  v-for="metric in metrics"
                  :key="metric.id"
                  class="w-[200px] h-[100px] border border-white rounded-2xl"
                >
                  <div class="flex justify-center items-center w-full h-full">
                    <div
                      class="w-[40%] rounded-2xl h-full flex justify-center items-center bg-[#f8c300]"
                    >
                      <h1 class="text-[50px] text-[#0d3a8f]">
                        {{ metric.YearsCount }}
                      </h1>
                    </div>
                    <div class="w-[60%]">
                      <p
                        v-html="metric.descript"
                        class="text-white text-left px-6 text-[18px]"
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="py-2 lg:py-3">
              <h1 class="text-[18px] lg:text-[20px] text-white text-left">
                Experiential metrics
              </h1>
              <div class="flex flex-col gap-2 md:flex-row py-1 pt-6">
                <div class="flex items-center gap-2 md:w-[50%]">
                  <img
                    src="@/assets/lpOnline/ItAllAddsUpicon1.webp"
                    alt="Technology-icon"
                  />
                  <p class="text-[13px] md:text-[16px] text-white">
                    Technology enabled learning and assessments
                  </p>
                </div>
                <div class="flex items-center gap-2 md:w-[50%]">
                  <img
                    src="@/assets/lpOnline/ItAllAddsUpicon2.webp"
                    alt="competencies-icon"
                  />
                  <p class="text-[13px] md:text-[16px] text-white">
                    Focus on competencies
                  </p>
                </div>
              </div>
              <div class="flex flex-col gap-2 md:flex-row py-1">
                <div class="flex items-center gap-2 md:w-[50%]">
                  <img
                    src="@/assets/lpOnline/ItAllAddsUpicon3.webp"
                    alt="Outcome-icon"
                  />
                  <p class="text-[13px] md:text-[16px] text-white">
                    Outcome based learning
                  </p>
                </div>
                <div class="flex items-center gap-2 md:w-[50%]">
                  <img
                    src="@/assets/lpOnline/ItAllAddsUpicon4.webp"
                    alt="Practical-exposure-icon"
                  />
                  <p class="text-[13px] md:text-[16px] text-white">
                    Practical exposure
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </template>
  
  <script>
  import $ from 'jquery'
  import 'slick-carousel'
  export default {
    name: 'ItAllAddUpView',
    data() {
      return {
        metrics: [
          {
            id: 1,
            YearsCount: '30+',
            descript: 'Years of<br> Excellence',
          },
          {
            id: 2,
            YearsCount: '43+',
            descript: 'Countries<br> Reached',
          },
          {
            id: 3,
            YearsCount: '365',
            descript: 'Days of<br> Engagement',
          },
          {
            id: 4,
            YearsCount: '2K+',
            descript: 'Hours of<br> Learning<br> through LMS',
          },
        ],
      }
    },
    mounted() {
      $(`.slick_allAddUp`).slick({
        infinite: true,
        autoplay: true,
        prevArrow: false,
        nextArrow: false,
        slidesToShow: 4,
        slidesToScroll: 2,
        dots: true,
        dotsClass: 'slick-dotsAddUP',
  
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 950,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      })
    },
  }
  </script>
  
  <style>
  .slick-dotsAddUP {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 1rem 0;
    list-style-type: none;
  }
  .slick-dotsAddUP li {
    margin: 0 0.25rem;
  }
  .slick-dotsAddUP button {
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    padding: 0;
    border: none;
    border-radius: 15px;
    background-color: silver;
    text-indent: -9999px;
  }
  .slick-dotsAddUP li.slick-active button {
    background-color: #f8c300;
    width: 2.5rem;
    height: 0.5rem;
  }
  </style>
  
