<template>
  <div>
    <!-- Chat Bot -->
    <div
      class="npf_chatbots"
      data-w=""
      style="display: none"
    ></div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "SocialMedia",
  data() {
    return {
      is_counselor: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://embed.tawk.to/6668195e981b6c56477bde85/1i03a67n5";
      document.body.appendChild(s);
      setTimeout(() => {
        // image alt name update
        $(document).ready(function () {
          var chatBotContainerTag = $("#__eechatIcon");

          if (chatBotContainerTag) {
            var imageTag = chatBotContainerTag.find("img");

            if (imageTag) {
              imageTag.attr({
                alt: "JAIN Online Chatbot",
                width: "60",
                height: "60",
              });
            }
          }
          var iframeTag = $("#chatbox_frame");
          iframeTag.attr("title", "JAIN Online Chatbot");
        });
      }, 2500);
    });
  },
};
</script>

<style></style>
