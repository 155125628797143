<template>
    <div
      class="w-full md:flex md:justify-center md:py-10 h-[500px] md:h-[600px] lg:h-[480px] overflow-x-hidden"
    >
      <div
        class="bg-gradient-to-b from-[#0F2377] to-[#00102F] h-[220px] md:h-[295px] w-full md:rounded-b-[40px]"
      >
        <section
          class="w-full  lg:w-[95%] 2xl:w-[70%]  mx-auto relative overflow-x-hidden xl:overflow-visible z-40"
        >
          <div class="flex flex-col md:gap-3 text-left md:text-center p-5 lg:p-0">
            <h1
              class="text-[#f8c300] font-semibold text-[24px] lg:text-[35px] mt-3"
            >
              Admission Process
            </h1>
            <p class="text-white text-[14px] lg:text-[18px]">
              Applying for admission into our programs has never been simpler -
              just follow these steps!
            </p>
          </div>
  
          <div>
            <div class="md:mt-8">
              <div
                class="slick_addmission-process flex flex-wrap justify-center gap-6"
              >
                <div
                  v-for="addmission in addmissions"
                  :key="addmission.id"
                  class="px-5 lg:px-0"
                >
                  <div
                    class="bg-white h-[190px] lg:w-[340px] mx-auto border flex flex-col gap-5 p-5 text-left rounded-xl"
                  >
                    <h1 class="text-[#001C54] text-[18px] font-bold">
                      {{ addmission.head }}
                    </h1>
                    <h3 class="text-black font-bold">
                      {{ addmission.content1 }}
                    </h3>
                    <p class="text-[14px]">
                      {{ addmission.content2 }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="absolute lg:top-7 top-0 right-[-72px] lg:right-[-200px] md:right-0 lg:-z-10"
          >
            <img
              src="@/assets/lpOnline/Path.png"
              alt=""
              class="w-[113px] h-[113px] lg:w-[232px] lg:h-[232px]"
            />
          </div>
  
          <div class="py-5 px-5">
            <button
              @click="scrollToTop()"
              class="text-[#001C54]  lg:px-7 py-[13px] w-full md:w-[150px] rounded-full bg-[#F8C300] font-bold"
            >
              Apply Now
            </button>
          </div>
        </section>
      </div>
    </div>
  </template>
  
  <script>
  import $ from "jquery";
  import "slick-carousel";
  
  export default {
    name: "AddmissionView",
  
    data() {
      return {
        addmissions: [
          {
            id: 1,
            head: "Step 1",
            content1: "Submit Application",
            content2: "Click here to visit the JAIN Online enrollment form",
          },
          {
            id: 2,
            head: "Step 2",
            content1: "Enroll for the elective",
            content2:
              "Sign up and complete the 4 essential steps to enroll in the program of your choice",
          },
          {
            id: 3,
            head: "Step 3",
            content1: "Make Payment",
            content2:
              "Pay fees through Net banking/Debit card/Credit card or using easy EMI with 0% interest",
          },
        ],
      };
    },
    mounted() {
      $(`.slick_addmission-process`).slick({
        infinite: true,
        autoplay: true,
        prevArrow: false,
        nextArrow: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        dotsClass: "slick-dots-admission",
  
        responsive: [
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 950,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    },
    methods: {
      scrollToTop() {
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
    },
  };
  </script>
  
  <style>
  .slick-dots-admission {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 1rem 0;
    list-style-type: none;
  }
  .slick-dots-admission li {
    margin: 0 0.25rem;
  }
  .slick-dots-admission button {
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    padding: 0;
    border: none;
    border-radius: 50px;
    background-color: silver;
    text-indent: -9999px;
  }
  .slick-dots-admission li.slick-active button {
    background-color: #f8c300;
    width: 2.5rem;
    height: 0.5rem;
  }
  </style>
  