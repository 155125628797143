<template>
  <div>
    <thankyouBannerVue />
    <degree />
    <itsAlladdsUpVue />
    <dreamVue />
    <aluminaiSpeakVue />
    <aluminaWorkVue />
    <admissionViewVue />
    <faqViewVue />
    <footerViewVue />
  </div>
</template>

<script>
import thankyouBannerVue from '@/components/lpThankyou/thankyouBanner.vue';
import degree from "@/components/lpdesign/degree.vue";
import itsAlladdsUpVue from "@/components/lpdesign/itsAlladdsUp.vue";
import dreamVue from "@/components/lpdesign/dream.vue";
import aluminaiSpeakVue from "@/components/lpdesign/aluminaiSpeak.vue";
import aluminaWorkVue from "@/components/lpdesign/aluminaWork.vue";
import admissionViewVue from "@/components/lpdesign/admissionView.vue";
import faqViewVue from "@/components/lpdesign/faqView.vue";
import footerViewVue from "@/components/lpdesign/footerView.vue";

export default {
  name: "lpThankyou",
  components: {
    degree,
    itsAlladdsUpVue,
    dreamVue,
    aluminaiSpeakVue,
    aluminaWorkVue,
    admissionViewVue,
    faqViewVue,
    footerViewVue,
    thankyouBannerVue
  },
};
</script>

<style scoped></style>
